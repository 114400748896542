import { cpUrl } from "content";

export default [
  {
    isShared: false,
    type: "Register Live",
    url: `${cpUrl}/register`
  },
  // {
  //   isShared: false,
  //   type: "Register Demo",
  //   url: `${cpUrl}/register`
  // },
  // {
  //   isShared: true,
  //   type: "Register Sub IB",
  //   url: `${cpUrl}/register`
  // }
];
